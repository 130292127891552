@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: "poppins", sans-serif;
  }
}
:root {
  --toastify-color-dark: #191d24 !important;
  --toastify-color-success: #83e933 !important;
}
